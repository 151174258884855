import { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import MDInput from 'components/MDInput'

function NotificationAdd() {
  const token = localStorage.getItem('userToken')
  const [users, setUsers] = useState([])
  const [message, setMessage] = useState([])
  const [userOptions, setUserOptions] = useState([])
  const [error, setError] = useState(null)
  const navigate = useNavigate()

  const getUserList = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}users/getAllUserList`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      if (!response.ok) {
        throw new Error('Failed to fetch data')
      }
      const jsonData = await response.json()

      const allUsers = jsonData.data.user.map((student) => ({
        title: student.address,
        id: student._id,
      }))
      console.log(allUsers)
      setUserOptions(allUsers)
    } catch (error) {
      console.error('Error fetching data:', error.message)
      //   setError('Error fetching data')
    }
  }, [token])

  useEffect(() => {
    getUserList()
  }, [getUserList])

  const handleUserChange = (event, newValue) => {
    setUsers(newValue)
  }

  const handleMessageChange = (e) => setMessage(e.target.value)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setError(null)

    console.log(users)
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}users/send-notification`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sender_type: 'Admin',
          notification_tag: 'multipleUsers',
          message: message,
          user_ids: users.map((user) => user.id),
        }),
      })

      const data = await response.json()

      if (!response.ok) {
        throw new Error(data.message || 'Failed to add new user')
      }
      navigate('/notifications')
    } catch (err) {
      setError(err.message)
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info">
                <MDTypography variant="h6" color="white">
                  Send Notification
                </MDTypography>
              </MDBox>

              <MDBox pt={4} pb={13} px={5}>
                <MDBox component="form" role="form" onSubmit={handleSubmit}>
                  <MDBox mb={2}>
                    <Stack spacing={3}>
                      <Autocomplete
                        multiple
                        id="tags-outlined"
                        options={userOptions}
                        getOptionLabel={(option) => option.title}
                        filterSelectedOptions
                        value={users}
                        onChange={handleUserChange}
                        renderInput={(params) => <TextField {...params} label="Select User" placeholder="Users" />}
                      />
                    </Stack>
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      label="Message"
                      variant="standard"
                      fullWidth
                      value={message}
                      onChange={handleMessageChange}
                      required
                      helperText={!message && 'Message is required'}
                    />
                  </MDBox>
                  {error && (
                    <MDBox mt={2}>
                      <MDTypography color="error">{error}</MDTypography>
                    </MDBox>
                  )}
                  <MDBox mt={4} mb={1}>
                    <MDButton variant="gradient" color="info" type="submit">
                      Send
                    </MDButton>{' '}
                    <MDButton variant="gradient" color="secondary" type="button" onClick={() => navigate('/notifications')}>
                      Cancel
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  )
}

export default NotificationAdd
