import { useState, useRef } from 'react'
import Card from '@mui/material/Card'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import BasicLayout from 'layouts/authentication/components/BasicLayout'
import bgImage from 'assets/images/bg.png'
import toast, { Toaster } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import logo from 'assets/images/logo.png'
import MDInput from 'components/MDInput'
import ReCAPTCHA from 'react-google-recaptcha'
function Basic() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const recaptchaRef = useRef()

  const onSubmitForm = async (e) => {
    e.preventDefault()
    const captchaValue = recaptchaRef.current.getValue()

    setLoading(true)
    if (!captchaValue) {
      toast.error('Please verify the reCAPTCHA!')
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}auth/login`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password, captchaValue }),
      })
      const data = await response.json()

      if (data.code === 0) {
        toast.error(data.message)
      }

      if (!response.ok) {
        throw new Error('Failed to login')
      }

      localStorage.setItem('userToken', data.data.token)
      localStorage.setItem('userData', JSON.stringify(data.data))
      toast.success('Login successful!')

      navigate('/dashboard')
    } catch (error) {
      console.error('Error during login')
    } finally {
      setLoading(false)
    }
  }

  return (
    <BasicLayout image={bgImage}>
      <Toaster />
      <Card>
        <MDBox variant="gradient" borderRadius="lg" coloredShadow="info" mx={2} mt={-3} p={2} mb={1} textAlign="center" style={{ background: '#000' }}>
          <MDBox component="img" src={logo} alt="Logo" width="10rem" />
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Welcome to Admin Login
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          {/* Change the onClick to onSubmit */}
          <MDBox component="form" role="form" onSubmit={onSubmitForm}>
            <MDBox mb={2}>
              <MDInput type="email" label="Email" variant="standard" fullWidth required value={email} onChange={(e) => setEmail(e.target.value)} />
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="password" label="Password" variant="standard" fullWidth required value={password} onChange={(e) => setPassword(e.target.value)} />
            </MDBox>
            <MDBox
              mb={2}
              style={{
                transform: 'scale(0.77)',
                WebkitTransform: 'scale(0.77)',
                transformOrigin: '0 0',
                WebkitTransformOrigin: '0 0',
              }}
            >
              {' '}
              <ReCAPTCHA ref={recaptchaRef} sitekey={process.env.REACT_APP_SITE_KEY} size="normal" />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton type="submit" variant="gradient" color="info" fullWidth disabled={loading}>
                {loading ? 'Signing in...' : 'Sign in'}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  )
}

export default Basic
