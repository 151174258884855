import React, { useState, useEffect, useCallback } from 'react'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import DataTable from 'examples/Tables/DataTable'
import { useNavigate } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast'
import Icon from '@mui/material/Icon'
import { Switch } from '@mui/material'
import { RotatingLines } from 'react-loader-spinner'
function Subadmin() {
  const navigate = useNavigate()
  const userToken = localStorage.getItem('userToken')
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false)

  const handleAddButtonClick = () => {
    navigate('/subadmin/add')
  }

  const [data, setData] = useState({
    columns: [
      {
        Header: 'Sr.No',
        accessor: 'srno',
        align: 'left',
      },
      {
        Header: 'Name',
        accessor: 'name',
        align: 'left',
      },

      {
        Header: 'Email',
        accessor: 'email',
        align: 'left',
      },

      {
        Header: 'Role',
        accessor: 'role',
        align: 'left',
      },

      {
        Header: 'Created At',
        accessor: 'created_at',
        align: 'left',
      },
      { Header: 'status', accessor: 'status', align: 'center' },
      { Header: 'action', accessor: 'action', align: 'center' },
    ],
    rows: [],
  })

  const formatDate = (isoDateString) => {
    const date = new Date(isoDateString)
    const options = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    }
    return date.toLocaleDateString('en-US', options)
  }

  const fetchData = useCallback(
    async (page) => {
      try {
        setLoading(true)
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}auth/get-subadmin-list/${page}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userToken}`,
          },
        })

        if (!response.ok) {
          toast.error('The coupon list is not found.')
        }

        const jsonData = await response.json()
        setLoading(false)
        const rows = jsonData.data.userData.map((user, index) => ({
          srno: (page - 1) * 10 + index + 1,
          name: user.name || '-',
          email: user.email || '-',
          role: user.role || '-',
          created_at: (
            <MDBox lineHeight={1} textAlign="left">
              <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
                {formatDate(user.createdAt)}
              </MDTypography>
            </MDBox>
          ),
          status: (
            <MDBox ml={-1}>
              <Switch checked={user.status === 'Active'} onChange={() => handleStatusChange(user._id, user.status)} color="success" />
            </MDBox>
          ),
          action: (
            <>
              <MDTypography component="a" href={`subadmin/view/${user._id}`} mr={2}>
                <Icon color="success">visibility</Icon>
              </MDTypography>
              <MDTypography component="a" href={`subadmin/edit/${user._id}`} mr={2}>
                <Icon color="secondary">edit</Icon>
              </MDTypography>
              <MDTypography component="a" onClick={() => handleDelete(user._id)}>
                <Icon color="error">delete</Icon>
              </MDTypography>
            </>
          ),
        }))

        const totalRows = jsonData.data.totalUsersCount

        setData((prevData) => ({
          ...prevData,
          rows,
          totalRows,
        }))
      } catch (error) {
        console.log('Error fetching data:', error.message)
      }
    },
    // eslint-disable-next-line
    [userToken], // Added 'userToken' as a dependency
  )

  const handleDelete = useCallback(
    async (selectedId) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}auth/delete-subadmin/${selectedId}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
        })

        if (!response.ok) throw new Error('Failed to delete user')

        toast.success('Subadmin deleted successfully')
        fetchData(page)
      } catch (error) {
        toast.error('Failed to delete user')
      }
    },
    [page, fetchData],
  )

  const handleStatusChange = useCallback(
    async (selectedId, currentStatus) => {
      const newStatus = currentStatus === 'Active' ? 'Inactive' : 'Active'
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}auth/update-status/${selectedId}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ status: newStatus }),
        })

        if (!response.ok) {
          throw new Error('Failed to update status')
        }
        toast.success('Subadmin status changed successfully')
        fetchData(page)
      } catch (error) {
        toast.error('Error updating status')
      }
    },
    [page, fetchData],
  )

  useEffect(() => {
    fetchData(page)
  }, [page, fetchData])

  function handlePrePage() {
    setPage((prevPage) => Math.max(prevPage - 1, 1))
  }

  function handleNextPage() {
    setPage((prevPage) => Math.min(prevPage + 1, totalPages))
  }
  const [rowsPerPage] = useState(10)
  const totalPagesRowCount = data?.totalRows || 0
  const totalPages = Math.ceil(totalPagesRowCount / rowsPerPage)

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Toaster />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  Subadmin
                </MDTypography>
                <MDButton variant="gradient" color="dark" size="large" onClick={handleAddButtonClick}>
                  Add Subadmin
                </MDButton>
              </MDBox>
              <MDBox pt={3}>
                {loading ? (
                  <MDTypography align="center">
                    <RotatingLines strokeColor="grey" strokeWidth="5" animationDuration="0.75" width="96" visible={true} />
                  </MDTypography>
                ) : (
                  <DataTable table={data} isSorted={false} entriesPerPage={false} showTotalEntries={false} canSearch={true} noEndBorder />
                )}

                <MDBox display="flex" justifyContent="center" alignItems="center" mt={10} mb={2} px={3} gap={2}>
                  {/* Previous Button */}
                  <MDButton
                    variant="contained"
                    color="info"
                    size="medium"
                    startIcon={<Icon>chevron_left</Icon>}
                    onClick={handlePrePage}
                    disabled={page === 1} // Disable on the first page
                    sx={{ minWidth: 120 }}
                  >
                    Previous
                  </MDButton>

                  {/* Current Page Display */}
                  <MDTypography variant="h6" color="text">
                    Page {page} of {totalPages}
                  </MDTypography>

                  {/* Next Button */}
                  <MDButton
                    variant="contained"
                    color="info"
                    size="medium"
                    endIcon={<Icon>chevron_right</Icon>}
                    onClick={handleNextPage}
                    disabled={totalPages === 0 || page === totalPages} // Disable on the last page
                    sx={{ minWidth: 120 }}
                  >
                    Next
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  )
}

export default Subadmin
