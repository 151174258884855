import Grid from '@mui/material/Grid'
import MDBox from 'components/MDBox'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'

import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import MDTypography from 'components/MDTypography'
import DocumentDarkImage from 'assets/images/timer.png'
import Card from '@mui/material/Card'
import Icon from '@mui/material/Icon'
import CollectionsIcon from '@mui/icons-material/Collections'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import CoinImage from 'assets/images/Intersect.png'
const formatDate = (dateString) => {
  const date = new Date(dateString)
  const options = { day: 'numeric', month: 'long', year: 'numeric' }
  const formattedDate = date.toLocaleDateString('en-GB', options) // Format: 20 September 2024
  const formattedTime = date.toLocaleTimeString('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  }) // Format: 10:14 AM/PM
  return `${formattedDate} ${formattedTime}`
}

function UserView() {
  const { id } = useParams()
  const [userData, setUserData] = useState(null)
  const token = localStorage.getItem('userToken')

  const fetchUserData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}users/getUserDetailByID/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      })
      const results = await response.json()

      setUserData(results.data.user)
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Upload failed. Please try again.'
      console.log(errorMessage)
    }
  }

  useEffect(() => {
    fetchUserData()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox mt={8}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            {/* MasterCard and PaymentMethod Section */}
            <Grid item xs={12} lg={8}>
              <Grid container spacing={3}>
                <Grid item xs={12} xl={12}>
                  <Card sx={{ background: 'rgba(50, 50, 50, 1)' }}>
                    <MDBox
                      position="absolute"
                      top={0}
                      left={0}
                      width="100%"
                      height="100%"
                      opacity={0.2}
                      sx={{
                        backgroundColor: 'black',
                      }}
                    />
                    <MDBox position="relative" zIndex={2} p={2}>
                      <MDTypography variant="h5" color="white" fontWeight="medium">
                        <AccountBalanceWalletIcon /> Wallet Address: <span style={{ fontSize: 14, color: 'gray' }}> {userData ? userData.address : 'Loading...'}</span>
                      </MDTypography>
                    </MDBox>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card id="delete-account">
                    <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
                      <MDTypography variant="h6" fontWeight="medium">
                        Uploaded Documents
                      </MDTypography>
                    </MDBox>
                    <MDBox p={2}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={12}>
                          <MDBox
                            borderRadius="lg"
                            p={3}
                            sx={{
                              border: ({ borders: { borderWidth, borderColor } }) => `${borderWidth[1]} solid ${borderColor}`,
                            }}
                          >
                            {userData && userData.documents && userData.documents ? (
                              <MDBox component="li" display="flex" justifyContent="space-between" alignItems="center" py={1} pr={1} mb={1}>
                                <MDBox lineHeight={1.125}>
                                  <MDTypography display="block" variant="button" fontWeight="medium">
                                    Passport
                                  </MDTypography>

                                  <MDTypography variant="caption" fontWeight="regular" color="text">
                                    {formatDate(userData.updatedAt)}
                                  </MDTypography>
                                </MDBox>

                                <MDBox display="flex" alignItems="center">
                                  <MDBox display="flex" alignItems="center" lineHeight={1} ml={3} sx={{ cursor: 'pointer' }}>
                                    {/* Link to open the PDF in a new tab */}
                                    <a
                                      href={userData.documentUrls} // Replace this with the actual URL of the PDF
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{
                                        textDecoration: 'none',
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <Icon fontSize="small">picture_as_pdf</Icon>
                                      <MDTypography variant="button" fontWeight="bold">
                                        &nbsp;PDF
                                      </MDTypography>
                                    </a>
                                  </MDBox>
                                </MDBox>
                              </MDBox>
                            ) : (
                              <MDTypography variant="body2">No Document Found</MDTypography>
                            )}
                          </MDBox>
                        </Grid>{' '}
                        <Grid item xs={12} md={12}>
                          <MDBox
                            borderRadius="lg"
                            p={3}
                            sx={{
                              border: ({ borders: { borderWidth, borderColor } }) => `${borderWidth[1]} solid ${borderColor}`,
                            }}
                          >
                            {userData && userData.photoDocument && userData.photoDocument ? (
                              <MDBox component="li" display="flex" justifyContent="space-between" alignItems="center" py={1} pr={1} mb={1}>
                                <MDBox lineHeight={1.125}>
                                  <MDTypography display="block" variant="button" fontWeight="medium">
                                    Photo
                                  </MDTypography>

                                  <MDTypography variant="caption" fontWeight="regular" color="text">
                                    {formatDate(userData.updatedAt)}
                                  </MDTypography>
                                </MDBox>

                                <MDBox display="flex" alignItems="center">
                                  <MDBox display="flex" alignItems="center" lineHeight={1} ml={3} sx={{ cursor: 'pointer' }}>
                                    {/* Link to open the PDF in a new tab */}
                                    <a
                                      href={userData.photoUrls} // Replace this with the actual URL of the PDF
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{
                                        textDecoration: 'none',
                                        display: 'flex',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <CollectionsIcon fontSize="small" />
                                      <MDTypography variant="button" fontWeight="bold">
                                        &nbsp;Image
                                      </MDTypography>
                                    </a>
                                  </MDBox>
                                </MDBox>
                              </MDBox>
                            ) : (
                              <MDTypography variant="body2">No Picture Found</MDTypography>
                            )}
                          </MDBox>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </Card>
                </Grid>
              </Grid>
            </Grid>

            {/* Invoices Section */}
            <Grid item xs={12} lg={4}>
              <Card sx={{ height: '100%' }}>
                <MDBox p={2}>
                  <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
                    {userData && userData.faceDocument ? (
                      <MDBox
                        component="img"
                        src={userData.faceCaptureUrls}
                        alt="Face capture"
                        width="100%"
                        sx={{ borderRadius: '8px', objectFit: 'cover' }} // Example styles
                      />
                    ) : (
                      <MDBox sx={{ textAlign: 'center', color: 'gray' }}>Face Verification is pending</MDBox>
                    )}
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>

        <MDBox mb={3}>
          <Grid container spacing={3}>
            {/* Image and Review Section */}
            <Grid item xs={12} md={8}>
              <Card>
                <MDBox
                  borderRadius="lg"
                  p={3}
                  sx={{
                    border: ({ borders: { borderWidth, borderColor } }) => `${borderWidth[1]} solid ${borderColor}`,
                  }}
                >
                  <MDBox display="flex" justifyContent="center" mb={2}>
                    <img alt="document" src={DocumentDarkImage} width={30} />
                  </MDBox>

                  <MDBox textAlign="center">
                    <MDTypography variant="h4">
                      {' '}
                      {userData && userData.status
                        ? userData.status === 'Under_review'
                          ? 'Under Review'
                          : userData.status === 'Processing'
                          ? 'Create NFT'
                          : userData.status
                        : '-'}{' '}
                    </MDTypography>
                  </MDBox>
                  <MDBox textAlign="center" mb={2}>
                    <MDTypography variant="caption" color="text" fontWeight="regular">
                      Your profile is currently under review. We will get back to you later.
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </Card>{' '}
            </Grid>

            {/* Empty Grid Item for potential future content */}
            <Grid item xs={12} md={4}>
              <Card sx={{ background: 'rgba(50, 50, 50, 1)' }}>
                <MDBox top={0} left={0} width="100%" height="100%" opacity={0.2} display="block" />
                <MDBox p={2} display="flex" alignItems="center">
                  <img alt="document" src={CoinImage} width={50} style={{ marginRight: '10px' }} />
                  <MDTypography variant="h5" color="white" fontWeight="medium">
                    Coin Payments
                  </MDTypography>
                </MDBox>
                <MDBox position="relative" zIndex={2} p={2}>
                  <MDTypography variant="h6" color="white" fontWeight="medium">
                    Amount Remaining: <span style={{ fontSize: 14, color: 'gray' }}> 13424234 BTC</span>
                  </MDTypography>{' '}
                  <MDTypography variant="h6" color="white" fontWeight="medium">
                    Payment ID: <span style={{ fontSize: 14, color: 'gray' }}> 13424234345345</span>
                  </MDTypography>{' '}
                  <MDTypography variant="h6" color="white" fontWeight="medium">
                    Buyer Email: <span style={{ fontSize: 14, color: 'gray' }}> support@gmail.com</span>
                  </MDTypography>{' '}
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  )
}

export default UserView
